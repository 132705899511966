<template>
	<b-container>
		<b-sidebar id="CoursesSidebar" :lazy="true" backdrop no-header>
			<b-card class="a-card-menu">
				<a-courses-filter :filters="subjects" :selectedFilters.sync="selectedSubjects" title="Predmety" />
				<a-courses-filter :filters="themes" :showThreshold="6" :selectedFilters.sync="selectedThemes" title="Témy" />
			</b-card>
		</b-sidebar>
		<b-row>
			<b-col class="d-none d-xl-block" xl="3" md="12">
				<!-- Filters -->
				<b-card class="a-card-menu">
					<a-courses-filter :filters="subjects" :selectedFilters.sync="selectedSubjects" @update:selectedFilters="fireFilterEvent('subjects', $event)" title="Predmety" />
					<a-courses-filter :filters="themes" :showThreshold="6" :selectedFilters.sync="selectedThemes" @update:selectedFilters="fireFilterEvent('themes', $event)" title="Témy" />
				</b-card>
			</b-col>

			<b-col xl="9" md="12">
				<span class="cancel-filter" v-if="searchedCourses" @click="cancelSearch">Zrušiť vyhľadávanie</span>
				<h1>
					Všetky <span class="w-color-primary z-fancy-underline">naše kurzy<svg-fancy-underline /></span>
				</h1>

				<!-- Courses tiles -->
				<w-loadable :items="courses" />
				<b-row align-h="start">
					<b-col v-for="(filteredCourse, i) of filteredCourses" :key="i" class="col-xxl-4 d-flex justify-content-center" xl="6" lg="4" md="6" sm="12">
						<z-path-card v-if="filteredCourse.slug === 'prirucka-prezitia-na-divokom-webe'" :prirucka="true" :path="filteredCourse" />
						<z-course-card v-else-if="filteredCourse._model == 'course'" :course="filteredCourse" />
						<z-path-card v-else :path="filteredCourse" />
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import wAxios				from '@/plugins/w/axios'

const api = {
	themes:			()			=> wAxios.get_data('v1/enum/theme'),
	subjects: 		()			=> wAxios.get_data('v1/enum/subject'),
	userflag: 		(data)		=> wAxios.post_auth_data('/userflag', data),
	paths: 			()			=> wAxios.get_data('v1/paths'),
	authPaths: 		()			=> wAxios.get_auth_data('v1/paths'),
	courses: 		() 			=> wAxios.get_data('v1/courses'),
	authCourses: 	() 			=> wAxios.get_auth_data('v1/courses')
}

export default {
	components: {
		'svg-fancy-underline': 	() => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline'),
		'a-courses-filter': 	() => import('./a-courses-filter.vue'),
		'z-path-card': 			() => import('@/plugins/lib@course/path/card/z-path-card.vue')
	},

	data() {
		return {
			showSidebar: false,
			themes: null,
			selectedThemes: [],
			uniqueThemes: [],
			subjects: null,
			selectedSubjects: [],
			uniqueSubjects: [],
			courses: null,
			rawCourses: null,
			searchedCourses: null,
			searchedText: null,
			filters: [],
		}
	},

	computed: {
		areCategoryFiltersSet() {
			return this.selectedThemes.length > 0 || this.selectedSubjects.length > 0
		},
		isTextFilterSet() {
			return this.searchedText !== null && this.searchedText.length > 0
		},
		isLoggedIn() {
			return this.$store.getters['wAuth/isLoggedIn']
		},
		isFromSearchBar() {
			return !!this.searchedCourses
		},

		filteredCourses() {
			if (!this.courses) return []

			let filteredCourses = this.courses

			// eslint-disable-next-line no-constant-condition
			if (!this.isFromSearchBar) {
				// Len filtre: Poradie od najnovsieho po najstarsi
				// Ani text ani filtre: Poradie od najnovsieho po najstarsi

				filteredCourses.sort((a, b) => {
					if(b.published_at) {
						let comparison = b.published_at.localeCompare(a.published_at)
						if (comparison != 0) {
							return comparison
						}

					}

					return b.id - a.id
				})
			}

			// Len text bez filtrov: Poradie ako pri search bari
			// Text s filtrami: Poradie ako pri search bari

			return this._filterByCategories(filteredCourses)
		}
	},

	async mounted() {
		try {
			let [themes, subjects, courses, paths] = await Promise.all([
				api.themes(),
				api.subjects(),
				this.isLoggedIn ? api.authCourses() : api.courses(),
				this.isLoggedIn ? api.authPaths() : api.paths()
			])

			this.themes = themes.sort((theme1, theme2) => {
				let comparison = theme1.sort_order - theme2.sort_order
				if (comparison != 0) {
					return comparison
				}

				return theme1.name.localeCompare(theme2.name)
			})
			this.subjects = subjects.sort((subject1, subject2) => {
				let comparison = subject1.sort_order - subject2.sort_order
				if (comparison != 0) {
					return comparison
				}

				return subject1.name.localeCompare(subject2.name)
			})

			courses.forEach(course => course._model = 'course')
			// NOTE: Comparison with integer, because '0' == true -> true
			courses = courses.filter(course => course.is_searchable == 1)
			paths.forEach(path => path._model = 'path')
			const coursesAndPaths = [ ...paths, ...courses ]
			this.rawCourses = coursesAndPaths.map(this._appendCustomUrl)

			// determine whether coureses should be displayed from api or search
			this.searchedCourses = this.$route.params.searchedCourses
			this.searchedText = this.$route.params.searchedText
			this.courses = (this.searchedCourses || coursesAndPaths).map(this._appendCustomUrl)

			// handle searchbar change when it changes on this screen to avoid routing hacks
			this.$events.on('courses-searched', results => {
				this.searchedCourses = results.searchResults
				this.searchedText = results.searchedText
				this.courses = this.searchedCourses.map(this._appendCustomUrl)
			})
		} catch(err) {
			this.$wToast.error(err)
		}
	},

	methods: {
		fireFilterEvent(filterType, filterSlugs) {
			const hasSelectedSubjects = Boolean(this.selectedSubjects.length)
			const hasSelectedThemes = Boolean(this.selectedThemes.length)
			const hasSearchedText = Boolean(this.searchedText)

			if((hasSelectedSubjects && hasSelectedThemes) || (hasSearchedText && (hasSelectedThemes || hasSelectedSubjects))) {
				this.$wEvent.log('course.filter.combined', {themes: this.selectedThemes.join(','), subjects: this.selectedSubjects.join(','), searched_text: this.searchedText})
			}else {
				if(filterSlugs.length) this.$wEvent.log('course.filter', {filter_value: `${filterType}: ${filterSlugs.join(',')}`})
			}
		},
		// onCategoryFiltersChanged(selectedCategories) {
		// 	this.selectedCategories = selectedCategories
		// },
		cancelSearch() {
			this.courses = this.rawCourses
			this.searchedCourses = null
			this.searchedText = ""
		},

		_filterByCategories(courses) {
			this.uniqueSubjects = new Set()
			this.uniqueThemes = new Set()

			// if no filters are active return all courses and enable them all (if they were disabled)
			if (!(this.selectedSubjects.length || this.selectedThemes.length) && !this.isFromSearchBar) {
				this._setDisabledFilters(true)
				return courses
			}

			const filteredCourses = courses.filter(course => {
				const isFilteredBySubject = !!course.subjects.find(subject => this.selectedSubjects.includes(subject))
				const isFilteredByTheme = !!course.themes.find(theme => this.selectedThemes.includes(theme))

				if ((!this.selectedSubjects.length || isFilteredBySubject) && (!this.selectedThemes.length || isFilteredByTheme)) {
					course.subjects.forEach(subject => this.uniqueSubjects.add(subject))
					course.themes.forEach(theme => this.uniqueThemes.add(theme))

					if (this.isFromSearchBar) {
						this._setDisabledFilters()
					}

					return true
				}

				return false
			})

			// disable/enable filter according to current selection
			this._setDisabledFilters()
			return filteredCourses
		},

		_setDisabledFilters(emptyFilters = false) {
			if (!this.subjects || !this.themes)
				return

			this.subjects.forEach(subject => {
				const isNotSelected = this.selectedSubjects.indexOf(subject.id) == -1
				const isNotAvailable = (!!this.selectedThemes.length || this.isFromSearchBar) && !this.uniqueSubjects.has(subject.slug)
				// const isNotAvailable = !this.uniqueSubjects.has(subject.slug)
				subject.disabled = !emptyFilters && isNotAvailable && isNotSelected
			})
			this.themes.forEach(theme => {
				const isNotSelected = this.selectedThemes.indexOf(theme.id) == -1
				const isNotAvailable = (!!this.selectedSubjects.length || this.isFromSearchBar) && !this.uniqueThemes.has(theme.slug)
				// const isNotAvailable = !this.uniqueThemes.has(theme.slug)
				theme.disabled = !emptyFilters && isNotAvailable && isNotSelected
			})
			// reactivity fix -> fix some other way?
			this.subjects.splice(this.subjects.length)
			this.themes.splice(this.themes.length)
		},

		_appendCustomUrl(c){
			c._url = `/${c._model == 'path' ? 'seria' : 'kurz'}/${c.slug || c.id}`
			return c
		},
	},
}
</script>
<style lang="sass" scoped>
.cancel-filter
	font-size: 15px
	cursor: pointer
	text-decoration: underline
	margin-bottom: 10px
	&:hover
		color: #4784ff
</style>